import { useEffect, useRef, useState } from 'react';

const useGraphContainer = () => {
  const graphContainerRef = useRef(null);
  const GraphContainer = (
    <div
      className="rounded overflow-hidden shadow-lg mt-3 p-0"
      ref={graphContainerRef}
    />
  );

  const [size, setSizeGraph] = useState({
    width: graphContainerRef.current?.parentElement.clientWidth,
    height: graphContainerRef.current?.parentElement.clientHeight,
  });

  const handleResize = () =>
    setSizeGraph({
      width: graphContainerRef.current?.parentElement.clientWidth,
      height: graphContainerRef.current?.parentElement.clientHeight,
    });

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { GraphContainer, graphContainerRef, size };
};

export default useGraphContainer;
