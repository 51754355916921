import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import enLang from '../../../assets/i18n/en.json';
import {
  selectedGraphConfigSelector,
  selectedGraphDataSelector,
  updateSelectedGraph,
  updateSelectedGraphConfig,
} from '../../../store';

function InputColors() {
  const config = useSelector(selectedGraphConfigSelector);
  const dispatch = useDispatch();
  const handleChange = ({ target }) =>
    dispatch(
      updateSelectedGraphConfig({ key: target.id, value: target.value })
    );
  return (
    <FormGroup>
      <Label for="colorway">{enLang.editor.graph_form.colors}</Label>
      <Input
        id="colorway"
        placeholder={enLang.editor.graph_form.colors_placeholder}
        value={config?.colorway}
        onChange={handleChange}
      />
    </FormGroup>
  );
}

function TreemapInputs() {
  const config = useSelector(selectedGraphConfigSelector);
  const dispatch = useDispatch();
  const handleChange = ({ target }) =>
    dispatch(
      updateSelectedGraphConfig({ key: target.id, value: target.value })
    );
  return (
    <>
      <InputColors />
      <FormGroup>
        <Label for="rootLabel">{enLang.editor.graph_form.root_label}</Label>
        <Input
          id="rootLabel"
          placeholder={enLang.editor.graph_form.root_label_placeholder}
          value={config?.rootLabel}
          onChange={handleChange}
          required
          invalid={!config?.rootLabel}
          style={{ borderColor: !config?.rootLabel ? 'gray' : '' }}
        />
        <FormFeedback style={{ color: 'gray' }}>
          {enLang.editor.graph_form.root_label_help}
        </FormFeedback>
      </FormGroup>
    </>
  );
}

function SunburstInputs() {
  const config = useSelector(selectedGraphConfigSelector);
  const dispatch = useDispatch();
  const handleChange = ({ target }) =>
    dispatch(
      updateSelectedGraphConfig({ key: target.id, value: target.value })
    );
  return (
    <>
      <InputColors />
      <FormGroup>
        <Label for="splitHeaders">{enLang.editor.graph_form.headers}</Label>
        <Input
          id="splitHeaders"
          placeholder={enLang.editor.graph_form.headers_placeholder}
          value={config?.splitHeaders}
          onChange={handleChange}
          required
          invalid={!config?.splitHeaders}
          style={{ borderColor: !config?.splitHeaders ? 'gray' : '' }}
        />
        <FormFeedback style={{ color: 'gray' }}>
          {enLang.editor.graph_form.headers_placeholder_help}
        </FormFeedback>
      </FormGroup>
    </>
  );
}

function BarInputs() {
  const config = useSelector(selectedGraphConfigSelector);
  const dispatch = useDispatch();
  const handleChange = ({ target }) =>
    dispatch(
      updateSelectedGraphConfig({ key: target.id, value: target.value })
    );
  return (
    <>
      <FormGroup>
        <Label for="rows">{enLang.editor.graph_form.rows}</Label>
        <Input
          id="rows"
          type="number"
          placeholder={enLang.editor.graph_form.rows_placeholder}
          value={config?.rows}
          onChange={handleChange}
          required
        />
        <FormFeedback style={{ color: 'gray' }}>
          {enLang.editor.graph_form.rows_placeholder_help}
        </FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="columns">{enLang.editor.graph_form.columns}</Label>
        <Input
          id="columns"
          type="number"
          placeholder={enLang.editor.graph_form.columns_placeholder}
          value={config?.columns}
          onChange={handleChange}
          required
        />
        <FormFeedback style={{ color: 'gray' }}>
          {enLang.editor.graph_form.columns_placeholder_help}
        </FormFeedback>
      </FormGroup>
    </>
  );
}

function InputData() {
  const data = useSelector(selectedGraphDataSelector);
  const dispatch = useDispatch();
  const handleChange = ({ target }) =>
    dispatch(updateSelectedGraph({ key: target.id, value: target.value }));
  return (
    <FormGroup>
      <Label for="data">{enLang.editor.graph_form.data}</Label>
      <Input
        id="data"
        type="textarea"
        placeholder={enLang.editor.graph_form.data_placeholder}
        value={data}
        onChange={handleChange}
      />
    </FormGroup>
  );
}

const complementaryInputs = {
  TREEMAP: TreemapInputs,
  SUNBURST: SunburstInputs,
  GRAPH: InputData,
  BAR: BarInputs,
  SCATTER: BarInputs,
};

export default complementaryInputs;
