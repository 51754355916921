import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col } from 'reactstrap';
import {
  generateGraphData,
  selectedGraphConfigSelector,
  selectedGraphTypeSelector,
} from '../../../store';
import enLang from '../../../assets/i18n/en.json';

const validKeys = {
  SUNBURST: ['splitHeaders'],
  TREEMAP: ['rootLabel'],
  BAR: [],
  SCATTER: [],
};

function SendFileButton() {
  const selectedGraphConfig = useSelector(selectedGraphConfigSelector);
  const selectedGraphType = useSelector(selectedGraphTypeSelector);
  const verifyConfig = (graphConfig) =>
    graphConfig === undefined ||
    validKeys[selectedGraphType].some((key) => !selectedGraphConfig[key]);
  const dispatch = useDispatch();
  const fileElem = useRef(null);

  const onSelectFile = ({ target }) => {
    const [tableFile] = target.files;
    if (!tableFile) {
      return;
    }
    dispatch(generateGraphData(tableFile));
  };

  const redirectClick = () => fileElem.current.click();

  return (
    <Col>
      <Button
        type="button"
        color="info"
        className="text-white"
        block
        disabled={verifyConfig(selectedGraphConfig)}
        onClick={redirectClick}
      >
        {enLang.editor.graph_form.transform_data}
      </Button>
      <input
        className="visually-hidden"
        type="file"
        ref={fileElem}
        onChange={onSelectFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </Col>
  );
}

export default SendFileButton;
