import Plotly from 'plotly.js/dist/plotly-custom';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useGraphContainer } from '../../utils';
import enLang from '../../assets/i18n/en.json';

const CONFIGS = {
  TREEMAP: {
    type: 'treemap',
    branchvalues: 'total',
    textinfo: 'label',
    hovertemplate: `<b>%{label}</b><br />${enLang.editor.graph_labels.members}: %{value}<br />${enLang.editor.graph_labels.description}: %{text}<extra></extra>`,
  },
  SUNBURST: {
    type: 'sunburst',
    branchvalues: 'total',
    textinfo: 'label',
    hovertemplate: '<b>%{label}</b><br />%{text}<extra></extra>',
  },
};

const USE_SUBPLOT = ['SCATTER', 'BAR'];
const SUBPLOT_LAYOUT = { pattern: 'independent', barmode: 'group' };

function Graph({
  rawSeletedGraphData,
  selectedGraphType,
  selectedGraphConfig,
}) {
  const { GraphContainer, graphContainerRef, size } = useGraphContainer();
  const grid = USE_SUBPLOT.includes(selectedGraphType)
    ? { ...SUBPLOT_LAYOUT, ...selectedGraphConfig }
    : undefined;
  const config = {
    margin: { l: 0, r: 25, b: 0, t: 20 },
    colorway: selectedGraphConfig?.colorway?.split(/,\s*/g),
    grid,
  };
  let data = [
    {
      ...rawSeletedGraphData,
      ...CONFIGS?.[selectedGraphType],
    },
  ];
  if (USE_SUBPLOT.includes(selectedGraphType)) {
    data = JSON.parse(JSON.stringify(rawSeletedGraphData));
    config.margin.b = 25;
    config.margin.l = 25;
  }
  useEffect(() => {
    if (!data) return;

    Plotly.newPlot(graphContainerRef.current, data, config, {
      displaylogo: false,
    });
  }, [data]);

  useEffect(() => {
    if (!graphContainerRef.current) return;

    Plotly.relayout(graphContainerRef.current, size);
  }, [size]);

  useEffect(() => {
    if (!graphContainerRef.current) return;

    Plotly.relayout(graphContainerRef.current, {
      colorway: selectedGraphConfig?.colorway?.split(/,\s*/g),
      grid,
    });
  }, [selectedGraphConfig?.colorway, grid]);

  if (!rawSeletedGraphData) {
    return <div>{enLang.editor.graph_labels.empty_graph}</div>;
  }

  return GraphContainer;
}

Graph.propTypes = {
  rawSeletedGraphData: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  selectedGraphType: PropTypes.string,
  selectedGraphConfig: PropTypes.shape({ colorway: PropTypes.string }),
};

Graph.defaultProps = {
  rawSeletedGraphData: undefined,
  selectedGraphType: undefined,
  selectedGraphConfig: undefined,
};

export default Graph;
